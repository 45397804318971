import React from "react";
import styled, { withTheme } from "styled-components";

const Icon = styled.i`
  color: ${({ theme, color }) => (color ? color : theme.primary(1))};
  display: inline-block;
  font-size: ${({ size }) => size || 18}px;
`;

export default withTheme(props => (
  <Icon className={`fal ${props.icon}`} {...props} />
));
