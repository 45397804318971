import React from "react";
import styled, { withTheme } from "styled-components";

const Wrapper = styled.div`
    background: #fff;
`;

const Head = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    font-weight: bold;
    border-bottom: 1px solid ${({theme}) => theme.gray(1)};
`;

const Item =  styled.div`
    padding: 15px;
    border-bottom: 1px solid ${({theme}) => theme.gray(1)};
    display: flex;
    align-items: center;
    font-size: 14px;
    &:last-child{
        border-color: transparent;
    }
    span{
        margin-right: 10px;
        background: ${({theme}) => theme.primary(1)};
        width: 6px;
        height: 6px;
        border-radius: 5px;
    }
`

export default withTheme(props => (
  <Wrapper>
      <Head>
          {props.title}
      </Head>
      {props.points.map(point => (
          <Item key={point}>
              <span></span>
              {point}
          </Item>
      ))}
  </Wrapper>
));
