import React, { Component } from 'react'
import faker from 'faker'
import Layout from '../../components/Layout/Layout';
import Grid from '../../components/Layout/Grid';
import TaskCard from '../../components/Tasks/TaskCard';


const data = [];

for(let i = 0; i < 10; i++){
    data.push({
        title: faker.company.catchPhraseNoun(),
        image: faker.image.image(),
        price: faker.commerce.price()
    })
}
export default class IndexView extends Component {
    render() {
        return (
            <Layout>
                <Grid margin={"0"} gap="20px" columns={3}>
                    {data.map(item => (
                        <TaskCard key={item.title + item.price} image={item.image} title={item.title} price={item.price}></TaskCard>
                    ))}
                </Grid>
            </Layout>
        )
    }
}
