import React, { Component } from "react";
import axios from "axios";

import Alert from "../components/Form/Alert";

export default class ErrorHandler extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null
    };
  }

  componentDidMount() {
    axios.interceptors.response.use(
      function(response) {
        // Do something with response data
        return response;
      },
      error => {
        // Do something with response error
        if (!error.response) {
          this.setState({
            error: true
          });

          setTimeout(() => {
            this.setState({
              error: false
            });
          }, 4000);
        }

        return Promise.reject(error);
      }
    );
  }

  render() {
    const { error } = this.state;
    return (
      <div>
        {error && <Alert visible={error} message="Something went wrong" />}
      </div>
    );
  }
}
