import React from "react";
import styled, { withTheme } from "styled-components";
import media from 'styled-media-query';

const Input = styled.input`
  color: ${({ theme }) => theme.dark(1)};
  padding: 15px 25px;
  display: inline-block;
  cursor: pointer;
  background-color: ${({ theme }) => theme.gray(1)};
  font-size: 14px;
  letter-spacing: 2px;
  transition: 0.5s linear background-color;
  border-radius: 4px;
  border: 0px;
  flex: 1;
  min-width: ${({ minWidth }) => minWidth ? minWidth : 'auto' };
  ${media.lessThan('small')`
    min-width: auto!important;
  `}
  &:focus {
    outline: none;
  }
`;

const Icon = styled.i`
  color: ${({ theme }) => theme.primary(1)};
  margin-right: 10px;
  display: inline-block;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  margin: ${({margin}) => margin ? margin : '0px 0px 10px 0px'};
`;

export default withTheme(props => {
  return (
    <Wrapper {...props}>
      {props.icon && !props.right ? (
        <Icon style={{ margin: "0 15px" }} className={`fal ${props.icon}`} />
      ) : (
        false
      )}
      <Input {...props} />
      {props.icon && props.right ? (
        <Icon style={{ margin: "0 15px" }} className={`fal ${props.icon}`} />
      ) : (
        false
      )}
    </Wrapper>
  );
});
