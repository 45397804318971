import React, { Component } from 'react'
import Layout from '../../components/Layout/Layout';
import Parallax from '../../components/Modules/Parallax'
import Button from '../../components/Form/Button'
import Grid from '../../components/Layout/Grid';
import Ups from '../../components/Utils/Ups';

export default class HomePage extends Component {
    render() {
        return (
            <Layout>
                <Parallax url="https://images.pexels.com/photos/380769/pexels-photo-380769.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260">
                    <div>
                        <h1 style={{ marginBottom: 20 }}>
                            Makkelijk in contact komen met bedrijven
                        </h1>
                        <Button icon="fa-user">
                            Registreer als student
                        </Button>
                    </div>
                    <div >
                        <h1 style={{ marginBottom: 20 }}>
                            Talentvolle student voor uw taken
                        </h1>
                        <Button secondary icon="fa-building">
                            Registreer als bedrijf
                        </Button>
                    </div>
                </Parallax>

                <div style={{ margin: '40px 0'}}>
                    <Grid columns={3} gap="15px">
                        <Ups 
                            title="Voor studenten" 
                            points={[
                                "Gebruik je kennis in de praktijk",
                                "Kom in contact met werkgevers",
                                "Kies je eigen taken",
                                "Doe werk dat bij je past"
                        ]} />
                        <Ups 
                            title="Voor bedrijven" 
                            points={[
                                "Werk met gemotiveerde studenten",
                                "Geen gedoe met contracten",
                                "Snel een passende werkkracht",
                                "Ideaal voor taken met lage prioriteit"
                            ]}
                        />
                        <Ups 
                            title="Hoe werkt het?"
                            points={[
                                "Maak een opdracht aan",
                                "Krijg reactie van gedreven studenten",
                                "Match met een student",
                                "Betaal de student"
                            ]}
                        />
                    </Grid>
                </div>
            </Layout>
        )
    }
}
