import React from "react";
import styled, { withTheme } from "styled-components";
import Container from '../Container'
import Logo from '../Logo'
import Row from '../Row'
import Input from '../../Form/Input'
import Avatar from '../../Utils/Avatar'
import NavItem from "./NavItem";

const Nav = styled.div`
  position: fixed;
  top: 0;
  z-index: 998;
  left: 0;
  right: 0;
`;

const Bar = styled.div`
  width: 100%;
  background: ${({theme}) => theme.background(1)};
  border-bottom: 1px solid ${({theme}) => theme.gray(1)};
  min-height: 60px;
`

const routes = [
  {
    label: 'Home',
    to: '/',
  },
  {
    label: 'Opdrachten',
    to: '/tasks',
  }
]

export default withTheme(props => {
  return (
    <Nav {...props}>
      <Bar>
        <Container>
          <Row style={{minHeight: 60}} justifyContent="space-between">
            <Logo />
            <Input 
              minWidth="200px"
              // right
              // icon="fa-search"
              margin="0"
              placeholder="Zoek opdrachten"
            />
            <Row>
              <Avatar size={40} />
            </Row>
          </Row>
        </Container>
      </Bar>
      <Bar>
        <Container>
            <Row style={{minHeight: 60}} justifyContent="center">
              {routes.map(item => (
                <NavItem 
                  {...item}
                  active={window.location.pathname === item.to}
                  key={item.to}
                />
              ))}
              
            </Row>
        </Container>
      </Bar>
    </Nav>
  );
});
