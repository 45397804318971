import React from "react";
import styled, { withTheme } from "styled-components";

const Row = styled.div`
  margin: ${props => props.margin || 0};
  display: flex;
  align-items: center;
  justify-content: ${props => props.justifyContent || "flex-start"};
`;

export default withTheme(Row);
