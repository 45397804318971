import React, { Component } from "react";
import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

// import Player from "./components/Player/Player";
import theme from "./lib/theme";
import ErrorHandler from "./lib/ErrorHandler";

import HomePage from './views/Landing/HomePage'
import TaskIndexView from './views/Tasks/IndexView';


const PrivateRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    auth={auth}
    render={props => (auth ? <Component {...props} /> : <Redirect to="/" />)}
  />
);

const AdminRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    auth={auth}
    render={props =>
      auth && auth.admin ? (
        <Component {...props} />
      ) : (
        <Redirect to="/requests" />
      )
    }
  />
);

class AppRouter extends Component {
  componentDidMount() {
    document.body.style.background = theme.gray();
  }
  render() {
    // let auth = this.props.user.data;

    return (
      <div>
        <Router>
          <ThemeProvider theme={theme} style={{ width: "100%" }}>
            <>
              <ErrorHandler />
              <Route path="/" exact component={HomePage} />
              <Route path="/tasks" exact component={TaskIndexView} />

{/* 
              <PrivateRoute
                auth={auth}
                path="/orders"
                exact
                component={OrderIndexView}
              />
              <PrivateRoute
                auth={auth}
                path="/orders/:id"
                exact
                component={OrderShowView}
              />
              <PrivateRoute
                auth={auth}
                path="/agenda"
                exact
                component={AgendaIndexView}
              /> */}
            </>
          </ThemeProvider>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppRouter);
