import React, { Component } from "react";
import styled from "styled-components";
// import Loading from "../Utils/Loading";
import Nav from "./Nav/Nav";
import Center from "./Center";
import Icon from "../Utils/Icon";
import Container from "./Container";

const Content = styled.div`
  padding: 120px 0px 0px 0;
  margin: 40px 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  flex:1;
`;
const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background: #fff;
`;

const Copyright = styled.div`
  opacity: 0.5
`

export default class Layout extends Component {
  render() {
    const { children, loading } = this.props;
    return (
      <>
        <Nav />
        <Content>
          {loading ? (
            <Center full>
              <Icon icon="fa-spin fa-spinner-third" />
            </Center>
          ) : (
            <Container>
              {children}
            </Container>
            
          )}
        </Content>
        {/* <Footer>
          <Copyright>
            Copyright © 2019 Students4Business Inc. All rights reserved
          </Copyright>
        </Footer> */}
      </>
    );
  }
}
