import React from "react";
import styled, { withTheme } from "styled-components";

const Banner = styled.div`
  height: 200px;
  background-image: url("${({ src }) => src}");
  width: 100%;
  background-repeat: no-repeat;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  background-position: center;
  position: relative;
`;

const AbsoluteWrapper = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 88;
`

export default withTheme(props => (
  <Banner src={props.src}> 
    {props.icon ? (
      <AbsoluteWrapper>
        {props.icon}
      </AbsoluteWrapper>
    ) : false}
  </Banner>
));
