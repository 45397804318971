import React from "react";
import styled, { withTheme } from "styled-components";

const Button = styled.div`
  color: #fff;
  padding: 15px 25px;
  display: inline-block;
  cursor: pointer;
  opacity: 1;
  background-color: ${({ theme, secondary }) => secondary ? theme.secondary(1) :  theme.primary(1)};
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: lowercase;
  transition: 0.5s linear opacity;
  border-radius: 4px;
  text-align: center;
  &:hover {
    opacity: 0.8;
  }
`;

const Icon = styled.i`
  color: #fff;
  margin-left: 10px;
  display: inline-block;
`;

export default withTheme(props => (
  <Button {...props}>
    {props.loading ? (
      <Icon
        style={{ marginLeft: 0 }}
        className={"fal fa-spin fa-spinner-third"}
      />
    ) : (
      <>
        {props.children}
        {props.icon ? <Icon className={`fal ${props.icon}`} /> : false}
      </>
    )}
  </Button>
));
