import React from "react";
import styled, { withTheme } from "styled-components";
import media from 'styled-media-query'

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns || 1}, 1fr);
  grid-template-rows: minmax(${({ rowHeight }) => rowHeight}, 1fr);
  grid-gap: ${({ gap }) => gap || 0};
  ${media.between('medium', 'large')`
    grid-template-columns: 1fr 1fr;
  `};
  ${media.lessThan('medium')`
    grid-template-columns: 1fr;
  `};
`;

export default withTheme(Grid);
