import React from 'react'
import styled, { withTheme } from "styled-components";
import media from 'styled-media-query';

const Background = styled.div`
    background-image: url("${({url}) => url}");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    min-height: 400px;
    position: relative;
    display: flex;
    color: #fff;
`;

const Wrapper = styled.div`
    z-index: 2;
    position: relative;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${media.lessThan('medium')`
        flex-direction: column;
        justify-content: space-around;
        div{
            text-align: center!important;
        }
        h1{
            font-size: 1.6rem;
        }
    `}
    div:last-child{
        text-align: right;
    }
    div:first-child{
        text-align: left;
    }
`

const Shadow = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${({ theme }) => theme.dark(1)};
    z-index: 1;
    opacity: 0.4;
`


export default withTheme(props => (
    <Background url={props.url}>
        <Shadow />
        <Wrapper>
            {props.children}
        </Wrapper>
    </Background>
));
