import React from "react";
import styled, { withTheme, keyframes } from "styled-components";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  10% {
   opacity: 1;
  }
  90% {
   opacity: 1;
  }
  100%{
    opacity: 0;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 25px;
  z-index: 9999;
  display: flex;
  justify-content: center;
  animation: 4s ${fadeIn} ease-in forwards;
`;
const Alert = styled.div`
  background-color: ${({ theme }) => theme.dark(1)};
  padding: 20px;
`;

const Text = styled.p`
  color: ${({ theme }) => theme.background(1)};
  display: inline-block;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
`;

export default withTheme(props => (
  <Wrapper {...props}>
    <Alert className={`fal ${props.icon}`} {...props}>
      <Text>{props.message || "Something went wrong"}</Text>
    </Alert>
  </Wrapper>
));
