import React from "react";
import styled, { withTheme } from "styled-components";
import Banner from '../Utils/Banner'
import Row from "../Layout/Row";
import Button from "../Form/Button";
import Icon from "../Utils/Icon";

const Wrapper = styled.div`
  display: block;

`;

const Label = styled.span`
  display: block;
  font-size: 16px;
`;

const Head = styled.div`
    background-color: #fff;
    padding:15px;
`
const Body = styled.div`
    position: relative;
    background-color: #fff;
    padding: 0 15px 15px 15px;
`

const InfoItem = styled.div`
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-bottom: 5px;
    span{
        display: inline-block;
        min-width: 100px;
        font-weight: bold;
    }
`

const ButtonWrapper = styled.div`
    ${'' /* position: absolute;
    bottom: 15px;
    right: 15px; */}
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 15px;
`

const IconWrapper = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    transition: 0.5s ease transform;
    transform: scale(1);
    cursor: pointer;
    &:hover {
        transform: scale(1.1);
    }
`

export default withTheme(props => (
    <Wrapper>
        <Banner src={props.image} icon={<IconWrapper><Icon icon="fa-star" color="#000" /></IconWrapper>} />
        <Head>
            <Row justifyContent="space-between">
                <Label>
                    {props.title || ""}
                </Label>
                <Label style={{ minWidth: 100, textAlign: 'right', fontWeight: 'bold'}}>
                    &euro; {props.price}
                </Label>
            </Row>
        </Head>
        <Body>
            <Row style={{ alignItems: 'flex-start', flexDirection: 'column'}} justifyContent="space-between">
                <div>
                    <InfoItem>
                        <span>Niveau:</span>
                        HBO
                    </InfoItem>
                    <InfoItem>
                        <span>Studie:</span>
                        Rechten
                    </InfoItem>
                    <InfoItem>
                        <span>Typen:</span>
                        Onderzoek
                    </InfoItem>
                    <InfoItem>
                        <span>Location:</span>
                        Thuis
                    </InfoItem>
                </div>
                <ButtonWrapper>
                    <Button>
                        Meld je aan
                    </Button>
                </ButtonWrapper>
            </Row>
        </Body>

        
    </Wrapper>
));
